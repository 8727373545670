import * as React from 'react'
import { Link } from 'gatsby'
import portfolioCardStyle from '../styles/components/portfolioCardStyle'

type PortfolioCardProps = {
  photoUrl: string
  title: string | null
  slug: string | null
}

const PortfolioCard = ({ photoUrl, title, slug }: PortfolioCardProps) => (
  <Link css={portfolioCardStyle} to={`/portfolios/${slug}`}>
    <div className="portfolio_card">
      <img src={photoUrl} alt={title || 'photo'} />
      <div className="hover_text">
        <p>{title}</p>
      </div>
    </div>
  </Link>
)

export default PortfolioCard
