import { css } from '@emotion/react'

const portfolioGridStyle = css`
  h2 {
    font-family: 'Montserrat', sans-serif;
    color: #3d3d3d;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 40px;

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .container {
    display: flex;
    column-gap: 40px;

    @media (max-width: 767px) {
      flex-direction: column;
      column-gap: 0px;
      row-gap: 15px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    width: calc(100% / 3);

    @media (max-width: 767px) {
      width: 100%;
      row-gap: 15px;
    }
  }
`
export default portfolioGridStyle
