import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

type HeadProps = {
  title?: string | null
  description?: string | null
  ogUrl?: string
  ogImageUrl?: string | null
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
const HeadTag: React.FC<HeadProps> = ({
  title,
  description,
  ogUrl,
  ogImageUrl,
}) => {
  const { site } = useStaticQuery(query)
  const formattedTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title
  const formttedDescription = description || site.siteMetadata.description
  const formattedOgUrl = ogUrl || site.siteMetadata.siteUrl
  const formattedOgImageUrl =
    ogImageUrl || `${site.siteMetadata.siteUrl}/images/ogp/default.png`

  return (
    <>
      <title>{formattedTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={formttedDescription} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={formattedOgUrl} />
      <meta property="og:image" content={formattedOgImageUrl} />
      <meta property="og:description" content={formttedDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creater" content="@meimei_fish" />
      <meta name="twitter:site" content="@meimei_fish" />
    </>
  )
}

export default HeadTag
