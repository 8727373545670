import { css } from '@emotion/react'

const portfolioCardStyle = css`
  .portfolio_card {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      .hover_text {
        opacity: 1;
      }
    }
  }

  .hover_text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: 0.3s ease-in-out;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #eeeeee;
      font-size: 1.8rem;
      font-family: 'Montserrat', 'Kosugi Maru', sans-serif;
      width: 80%;
      text-align: center;
      font-weight: 400px;
    }
  }
`

export default portfolioCardStyle
