import * as React from 'react'
import portfolioGridStyle from '../styles/components/portfolioGridStyle'
import PortfolioCard from './portfolio_card'

type edge = {
  readonly node: {
    readonly id: string
    readonly title: string | null
    readonly slug: string | null
    readonly primary_photo: {
      readonly url: string | null
    } | null
  }
}

type PortfolioGridProps = {
  edges: readonly edge[]
}

const splitArray = (arr: readonly edge[], n = 1): edge[][] => {
  const perSize = Math.floor(arr.length / n)
  const modulo = arr.length % n
  const result = []
  let start = 0

  for (let i = 0; i < n; i += 1) {
    const end = start + perSize + (modulo > 0 && modulo > i ? 1 : 0)
    result.push(arr.slice(start, end))
    start = end
  }
  return result
}

const PortfolioGrid: React.FC<PortfolioGridProps> = ({
  edges,
}: PortfolioGridProps) => {
  const portfolios = splitArray(edges, 3)
  return (
    <div css={portfolioGridStyle}>
      <h2 id="portfolio">Portfolio</h2>
      <div className="container">
        <div className="column">
          {portfolios[0].map((portfolio) => (
            <PortfolioCard
              photoUrl={`${portfolio.node?.primary_photo?.url}?fm=webp&w=600`}
              title={portfolio.node?.title}
              slug={portfolio.node?.slug}
              key={portfolio.node?.id}
            />
          ))}
        </div>
        <div className="column">
          {portfolios[1].map((portfolio) => (
            <PortfolioCard
              photoUrl={`${portfolio.node?.primary_photo?.url}?fm=webp&w=600`}
              title={portfolio.node?.title}
              slug={portfolio.node?.slug}
              key={portfolio.node?.id}
            />
          ))}
        </div>
        <div className="column">
          {portfolios[2].map((portfolio) => (
            <PortfolioCard
              photoUrl={`${portfolio.node?.primary_photo?.url}?fm=webp&w=600`}
              title={portfolio.node?.title}
              slug={portfolio.node?.slug}
              key={portfolio.node?.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PortfolioGrid
