import { css } from '@emotion/react'

const heroStyle = css`
  position: relative;
  width: 100%;

  .hero-wrapper {
    position: relative;
    width: 100%;

    .hero-img {
      width: 100%;
      object-fit: cover;
    }

    .hero-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #eeeeee;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 5rem;
      @media (max-width: 767px) {
        font-size: 2.8rem;
      }
    }

    .header-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
`

export default heroStyle
