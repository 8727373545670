import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import TopLayout from '../components/top_layout'
import PortfolioGrid from '../components/portfolio_grid'
import HeadTag from '../components/headTag'

const IndexPage: React.FC<PageProps<Queries.FetchPortpoliosQuery>> = ({
  data,
}) => (
  <TopLayout>
    <PortfolioGrid edges={data.allContentfulPortfolio.edges} />
  </TopLayout>
)

export const query = graphql`
  query FetchPortpolios {
    allContentfulPortfolio(
      limit: 30
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          primary_photo {
            url
          }
          slug
        }
      }
    }
  }
`

export default IndexPage

export const Head = () => <HeadTag />
