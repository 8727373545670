import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HomeHeader from './homeHeader'
import heroStyle from '../styles/components/heroStyle'

export const query = graphql`
  query {
    allContentfulHeroImage(sort: { order: DESC, fields: id }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
  }
`

const Hero = () => {
  const { allContentfulHeroImage } = useStaticQuery(query)
  const heroImageUrl = `${allContentfulHeroImage.edges[0].node.image.url}?w=1920&h=1080`

  return (
    <div css={heroStyle}>
      <div className="hero-wrapper">
        <div className="header-wrapper">
          <HomeHeader />
        </div>
        <img className="hero-img" src={heroImageUrl} alt="meimei fish" />
        <h2 className="hero-text">meimei fish</h2>
      </div>
    </div>
  )
}

export default Hero
