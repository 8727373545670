import { css } from '@emotion/react'

const topBurgerMenuStyle = css`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }

  .link {
    color: #3d3d3d;
    text-decoration: none;
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    display: block;
    text-align: center;
  }

  .icon {
    width: 24px;
  }

  .sns {
    display: flex !important;
    justify-content: center;
    margin-top: 40px;
    column-gap: 50px;
  }

  // NOTE: react-burger-menu see https://github.com/negomi/react-burger-menu#styling
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 20px;
    height: 15px;
    right: 15px;
    top: 17px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
    height: 2px !important;
    border-radius: 10px;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #ffffff;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #ffffff;
    padding: 20px;
  }

  /* Individual item */
  .bm-item {
    padding: 1rem;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`

export default topBurgerMenuStyle
