import { css } from '@emotion/react'

const homeHeaderStyle = css`
  display: flex;
  background-color: rgba(217, 217, 217, 0.2);
  justify-content: space-between;
  width: 100%;
  height: 100px;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 767px) {
    height: 50px;
  }
`

export default homeHeaderStyle
