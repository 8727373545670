import React from 'react'
import homeHeaderStyle from '../styles/components/homeHeaderStyle'
import HeaderContent from './headerContent'

const HomeHeader = () => (
  <header css={homeHeaderStyle}>
    <HeaderContent />
  </header>
)

export default HomeHeader
